@keyframes slideInBottom {
	from {
		transform: translateY(100%);
	}
	to {
		transform: translateY(-20%);
	}
}
@keyframes slideInTop {
	from {
		transform: translateY(-100%);
	}
	to {
		transform: translateY(-20%);
	}
}
@keyframes slideInLeft {
	from {
		transform: translate(-100%, -20%);
	}
	to {
		transform: translate(0%, -20%);
	}
}
@keyframes slideInRight {
	from {
		transform: translate(100%, -20%);
	}
	to {
		transform: translate(0%, -20%);
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translate(0%, 0%);
	}
	to {
		opacity: 1;
		transform: translate(0%, -20%);
	}
}
